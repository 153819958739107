import { Controller } from "stimulus";

// Example
//
//   %span{data: {controller: 'clipboard'}}
//     %input{type: 'hidden', value: flex.invite_url(attendee.billing_invite.sid), readonly: true, data: {'clipboard-target': 'source'}}
//     %button.uk-icon-link{'uk-icon': 'icon: copy', 'uk-tooltip': 'Copy invite to clipboard', data: {action: 'clipboard#copy'}}
//
export default class extends Controller {
  static targets = [ 'source' ];

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value);
    App.setFlash('<i class="success">Copied invite!</i>');
  }
}
