// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application
// logic in a relevant structure within app/javascript and only use these pack
// files to reference that code so it'll be compiled.
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

NodeList.prototype.forEach = Array.prototype.forEach;
NodeList.prototype.map = Array.prototype.map;
NodeList.prototype.reduce = Array.prototype.reduce;
NodeList.prototype.filter = Array.prototype.filter;

HTMLCollection.prototype.forEach = Array.prototype.forEach;
HTMLCollection.prototype.map = Array.prototype.map;
HTMLCollection.prototype.reduce = Array.prototype.reduce;
HTMLCollection.prototype.filter = Array.prototype.filter;

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import LocalTime from "local-time";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);
require("@fortawesome/fontawesome-free/js/all");

import Flex, {
  initTrix,
  confirm, handleBuyNow, handleFilter,
  loadRecaptcha, recaptchaLoaded, recaptchaExecuted, recaptchaBeforeSend,
  FXLOAD
} from '@flex/hack-core';

window.initTrix = initTrix;

window.Rails = Rails;
window.LocalTime = LocalTime;
window.UIkit = UIkit;
window.Flex = Flex;
window.App = Flex;

Turbolinks.start()
ActiveStorage.start()
LocalTime.start()
Rails.start()

import { ready, PATCH } from "dom-boilerplate";

Rails.confirm = (message, el) => {
  // this is a hack for el being the input instead of the form on button_to
  const element = el.parentElement.matches('form') ? el.parentElement : el

  const isRemote = element.getAttribute('data-remote');

  if (isRemote) {
    //UIkit.modal.confirm(message, {stack: true}).then(() =>{
    UIkit.modal.confirm(message).then(() =>{
      const callback = Rails.fire(element, 'confirm:complete', [true]);
      Rails.handleRemote.call(element, {
        target: element,
        preventDefault: ()=>{},
        stopPropagation: ()=>{},
        stopImmediatePropagation: ()=>{},
      });
    }, () => {
      const callback = Rails.fire(element, 'confirm:complete', [false]);
    });
  } else {
    // TODO ... not sure how to do the submit without triggering the
    // dialog again
    //return confirm(message);
    //UIkit.modal.confirm(message, {stack: true}).then(() =>{
    UIkit.modal.confirm(message).then(() =>{
      const href = el.getAttribute('href');
      if (href) window.location.href = href;
    }, () => {
      console.log('Declined: ' + message)
    });
  }

  // let promise resolve
  return false;
};

// deprecated
Rails.delegate(document, 'a[href="#buy-now"]', 'click', handleBuyNow);

Rails.delegate(
  document,
  'input[type=checkbox][data-filter], select[data-filter]',
  'change',
  handleFilter
);

document.addEventListener('turbolinks:before-cache', function() {
  document.querySelectorAll('.uk-notification').forEach(
    el => el.remove()
  )
});

window.onInteractivity = function () {
  loadRecaptcha();
}

// callback from google recaptcha
window.recaptchaLoaded = recaptchaLoaded;
window.recaptchaExecuted = recaptchaExecuted;

// SS
App.initRollcall = function() {
  App.initRemoteModal(
    '[data-fxtoggle=rollcall-attendance]',
    '#rollcall-attendance-modal',
    {
      onInsert: function(el) {
        console.log('onInsert');
        App.initRollcallModal(el);
      }
    }
  )
  console.log('initRollcall complete');
};

App.initOfferingAttendeeModals = function() {
  document.querySelectorAll('[data-fxtoggle=attendee-evaluation]').forEach((el) => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const modal = document.querySelector('#attendee-evaluation-modal');
      window.modelEl = modal;
      if (modal) {
        modal.setAttribute(
          'data-fxload',
          el.getAttribute('href')
        );
        UIkit.modal(modal).show();
      }
    })
  })

  UIkit.util.on('#attendee-evaluation-modal', 'beforeshow', function(event) {
    FXLOAD(event.target, {
      payloadEl: event.target.querySelector('.uk-modal-dialog .content')
    })
  });

  document.querySelectorAll('[data-fxtoggle=attendee-lesson]').forEach((el) => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const modal = document.querySelector('#attendee-lesson-modal');
      window.modelEl = modal;
      if (modal) {
        modal.setAttribute(
          'data-fxload',
          el.getAttribute('href')
        );
        UIkit.modal(modal).show();
      }
    })
  })

  UIkit.util.on('#attendee-lesson-modal', 'beforeshow', function(event) {
    FXLOAD(event.target, {
      payloadEl: event.target.querySelector('.uk-modal-dialog .content')
    })
  });

  document.querySelectorAll('[data-fxtoggle=attendee-attendances]').forEach((el) => {
    el.addEventListener('click', (event) => {
      event.preventDefault();
      const modal = document.querySelector('#attendee-attendances-modal');
      window.modelEl = modal;
      if (modal) {
        modal.setAttribute(
          'data-fxload',
          el.getAttribute('href')
        );
        UIkit.modal(modal).show();
      }
    })
  })

  UIkit.util.on('#attendee-attendances-modal', 'beforeshow', function(event) {
    FXLOAD(event.target, {
      payloadEl: event.target.querySelector('.uk-modal-dialog .content')
    })
  });
}


ready(function() {
  document.body.addEventListener("ajax:beforeSend", recaptchaBeforeSend);

  document.body.addEventListener('boilerplate:beforeFetch', (event) => {
    console.log('boilerplate:beforeFetch', event)
    event.detail.headers['X-CSRF-Token'] = Rails.csrfToken();
  });

  document.body.addEventListener("ajax:error", (event) => {
    console.log("ajax:error", event);
  });

  Flex.showFlash();

  App.checkTimezone();

  Flex.initSkuEditModal();
  Flex.initAdminMailModals();
  Flex.initFxUploader();

  Flex.initAddPaymentMethodModal();
  Flex.initCheckoutForm(); // TODO remove?

  initTrix();

  window.onscroll = window.onInteractivity;
  window.onmousedown = window.onInteractivity;
  window.onmousemove = window.onInteractivity;
  window.onkeypress = window.onInteractivity;
  window.ontouchstart = window.onInteractivity;

  if (window.Recaptcha) Recaptcha.load();

  // SS BEGIN

  App.initRemoteModal(
    '[data-fxtoggle=jurisdiction-certificate]',
    '#jurisdiction-certificate-modal'
  )

  App.initOfferingAttendeeModals();
  App.initRollcall();

  // Wistia uploader
  let wistiaUploaderElement = document.querySelector("#wistia_uploader");
  if (wistiaUploaderElement) {
    let accessToken = wistiaUploaderElement.getAttribute("data-wistia-access-token");
    let projectId = wistiaUploaderElement.getAttribute("data-wistia-project-id");

    window._wapiq = window._wapiq || [];
    _wapiq.push(function (W) {
      window.wistiaUploader = new W.Uploader({
        accessToken: accessToken,
        dropIn: "wistia_uploader",
        projectId: projectId,
      });

      window.wistiaUploader.bind(
        "uploadembeddable",
        function (file, media, embedCode, oembedResponse) {
          PATCH("wistia-refresh", {
            headers: {
              Accept: "application/javascript",
            },
          }).then((response) => {
            response.text().then((text) => {
              window.App.appendScript(text);
              window.wistiaUploader.removePreview();
            });
          });
        }
      );
    });
  }

  // Wistia player
  let wistiaPlayerElements = document.querySelectorAll(".wistia_player");
  wistiaPlayerElements.forEach((wistiaPlayerElement) => {
    console.log("Found a player!");
    let mediaId = wistiaPlayerElement.getAttribute("wistia-media-id");
    let attendeeLessonUrl = wistiaPlayerElement.getAttribute(
      "attendee-lesson-url"
    );
    let offeringLessonId = wistiaPlayerElement.getAttribute(
      "offering-lesson-id"
    );
    let userEmail = wistiaPlayerElement.getAttribute("user-email");
    //let accessToken = wistiaPlayerElement.getAttribute('data-wistia-access-token');
    //let projectId = wistiaPlayerElement.getAttribute('data-wistia-project-id');
    console.log("Found ", mediaId); //, accessToken, projectId);
    console.log("Found ", attendeeLessonUrl);

    window._wapiq = window._wapiq || [];
    // or use id: "_all" to target all!
    _wapiq.push({
      id: mediaId,
      onReady: function (video) {
        video.bind("play", function () {
          console.log("Ready:", mediaId);
          let visitorKey = video.visitorKey();
          console.log("Visitor Key:", visitorKey);
          let eventKey = video.eventKey();
          console.log("Event Key:", eventKey);
          video.email(userEmail);
          console.log("Getting email:", video.email());
          PATCH(attendeeLessonUrl, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/javascript",
            },
            body: JSON.stringify({attendee_lesson: {
              wistia_visitor_ref: visitorKey,
              wistia_event_ref: eventKey,
            }}),
          }).then((response) => {
            response.text().then((text) => {
              window.App.appendScript(text);
            });
          });
          return video.unbind;
        });
      },
    });
  });


  // Quizzes: T/F admin edit auto-submit
  window.App.submitFormOnRadioChange = function (form) {
    if (!form) { return };
    form.querySelectorAll('.autosubmit',).forEach(radio => {
      radio.addEventListener('change', (event) => {
        form.requestSubmit();
      });
    });
  }
  document.querySelectorAll('form.boolean-answer').forEach(form => {
    window.App.submitFormOnRadioChange(form);
  });

  // Quizzes: Question admin sort
  // Undisturbed (i.e. not yet working) copy/paste from quiz template
  //$('#questions').sortable({
  //  handle: '.handle',
  //  axis: 'y',
  //  helper: function(event, ui) {
  //    ui.children().each(function() { $(this).width($(this).width()); });
  //    return ui;
  //  },
  //  update: function(event, ui) {
  //    var $target = $(event.target);
  //    var ids = $target.children().map(function(idx, el) {
  //      return $(el).data('id');
  //    });
  //    var request = new XMLHttpRequest();
  //    request.open('PATCH', $target.data('sort-url'), true);
  //    request.setRequestHeader('Content-Type', 'application/json');
  //    request.setRequestHeader('Accept', 'application/json');
  //    request.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
  //    request.onload = function() {
  //      if (request.status >= 200 && request.status < 300) {
  //        var json = JSON.parse(request.response);
  //        var questions = json.questions;
  //        for (var i in questions) {
  //          var question = questions[i];
  //          $('#question-' + question.id + ' span.number').text(question.number);
  //        }
  //      }
  //    }.bind(this);
  //    request.send(JSON.stringify({ positions: ids.toArray() }));
  //  }
  //});

  var certificateProgress = document.getElementById("certificate-progress");
  window.certUp = UIkit.upload('#certificate-select', {
    url: 'certificate/template',
    multiple: false,
    name: 'template',
    beforeSend: function (environment) {
      var { data, method, headers, xhr, responseType } = environment;
      // 'Accept': 'application/javascript' will invoke create.js.*
      //
      // Might want to change to html and just return the image, and append
      // client side, but this allows flash etc.
      environment.headers = Object.assign(environment.headers, {
        'Accept': 'application/javascript',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken(),
        'X-Resource': this.$el.getAttribute('data-resource')
      });
      environment.method = 'PATCH';
    },
    loadStart: function (e) {
      certificateProgress.removeAttribute('hidden');
      certificateProgress.max = e.total;
      certificateProgress.value = e.loaded;
    },
    progress: function (e) {
      certificateProgress.max = e.total;
      certificateProgress.value = e.loaded;
    },
    loadEnd: function (e) {
      certificateProgress.max = e.total;
      certificateProgress.value = e.loaded;
    },
    complete: function (response) {
      App.appendScript(response.responseText);
    },
    completeAll: function (response) {
      setTimeout(function () {
        certificateProgress.setAttribute('hidden', 'hidden');
      }, 1000);
    },
    fail: function (err) {
      console.log(err);
    },
    error: function (err) {
      console.log(err);
    },
  });

  const knownLicensesUploader = '[data-fx="upload-known-licenses"]';
  var knownLicensesProgress = document.querySelector(`${knownLicensesUploader} progress`);
  window.certUp = UIkit.upload(knownLicensesUploader, {
    url: 'known-licenses/upload',
    multiple: false,
    name: 'csv',
    beforeSend: function (environment) {
      var { data, method, headers, xhr, responseType } = environment;
      environment.headers = Object.assign(environment.headers, {
        'Accept': 'application/javascript',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken(),
        'X-Resource': this.$el.getAttribute('data-resource')
      });
      environment.method = 'POST';
    },
    loadStart: function (e) {
      knownLicensesProgress.removeAttribute('hidden');
      knownLicensesProgress.max = e.total;
      knownLicensesProgress.value = e.loaded;
    },
    progress: function (e) {
      knownLicensesProgress.max = e.total;
      knownLicensesProgress.value = e.loaded;
    },
    loadEnd: function (e) {
      knownLicensesProgress.max = e.total;
      knownLicensesProgress.value = e.loaded;
    },
    complete: function (response) {
      App.appendScript(response.responseText);
    },
    completeAll: function (response) {
      setTimeout(function () {
        knownLicensesProgress.setAttribute('hidden', 'hidden');
      }, 1000);
    },
    fail: function (err) {
      console.log(err);
    },
    error: function (err) {
      console.log(err);
    },
  });

  const attendeeNotesForm = document.querySelector('form.attendee-notes');
  if (attendeeNotesForm) {
    const editor = attendeeNotesForm.querySelector('.notes');
    editor.addEventListener('trix-change', _.debounce((event) => {
      Rails.handleRemote.call(attendeeNotesForm, event)
    }, 2000));
  }

  const inviteAttendeeModalToggle = document.querySelector('a[href="#attendee-invite-modal"]');
  if (inviteAttendeeModalToggle) {
    inviteAttendeeModalToggle.addEventListener('click', (event) => {
      event.preventDefault();
      const attendeeId = event.target.getAttribute('data-attendee');
      const offering = event.target.getAttribute('data-name');
      const modal = document.querySelector('#attendee-invite-modal');
      const offeringSpan = modal.querySelector('h3 span');
      const input = modal.querySelector('input#invite_attendee_id');
      input.value = attendeeId;
      offeringSpan.innerHTML = offering;
      UIkit.modal(modal).show();
    });
  }

  // potential extract
  UIkit.util.on('[uk-sortable][data-url]', 'stop', function (e) {
    const list = e.target;
    const url = list.getAttribute('data-url');
    if (url) {
      const items = list.children;
      const ids = items.map(e => e.getAttribute('data-id'));
      console.log(ids);
      PATCH(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/javascript',
        },
        body: JSON.stringify({ ordered_ids: ids })
      })
      .then(response => response.text())
      .then(App.appendScript);
    }
  });
  // SS END
});

import "channels";
import "controllers";


