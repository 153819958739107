// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { addStimulusControllers } from "@flex/hack-core"

const application = Application.start()

// NOTE currently using local
// addStimulusControllers(application)

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

