export function ready(fn) {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
    console.log('top');
    fn();
  } else {
    if (window.Turbolinks && window.Turbolinks.supported) {
      document.addEventListener('turbolinks:load', fn);
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }
}

export function hasClass(el, name) {
  if (el.classList)
    return el.classList.contains(name);
  else
    return new RegExp('(^| )' + name + '( |$)', 'gi').test(el.className);
}

export function removeClass(el, name) {
  if (el.classList)
    el.classList.remove(name);
  else
    el.className = el.className.replace(new RegExp('(^|\\b)' + name.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}

export function addClass(el, name) {
  if (el.classList)
    el.classList.add(name);
  else
    el.className += ' ' + name;
}

export function trigger(el, name) {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  el.dispatchEvent(event);
}

export function triggerCustom(el, name, data) {
  if (window.CustomEvent) {
    var event = new CustomEvent(name, {detail: data});
  } else {
    var event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  el.dispatchEvent(event);
}

export function POST(url, options) {
  return request('POST', url, options);
};

export function PATCH(url, options) {
  return request('PATCH', url, options);
};

export function DELETE(url, options) {
  return request('DELETE', url, options);
};

export function GET(url, options) {
  return request('GET', url, options);
};

function request(method, url, options) {
  var headers = options.headers;
  delete options.headers;

  return fetch(url, Object.assign({
    method,
    headers: Object.assign({
      //'Content-Type': 'application/json',
      //'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': Rails.csrfToken()
    }, headers),
    credentials: 'same-origin',
  }, options));
};


// {
//   url: //override data-fxload on el
//   payloadEl: el to load content into
//   request: obj with options for request
// }
//
// Example
//  // event.target has data-fxload set.
//  UIkit.util.on('#offcanvas-product-edit', 'beforeshow', function (event) {
//    FXLOAD(event.target, {
//      payloadEl: event.target.querySelector('.content')
//    });
//  });
export function FXLOAD(el, options) {
  var url = event.target.getAttribute('data-fxload');
  if (options.url) url = options.url;

  if (url === null) {
    console.log('FXLOAD missing url');
    return;
  }

  if (!options.payloadEl) options.payloadEl = el;

  GET(url, Object.assign({
      headers: {
        'Content-Type': 'text/html',
        'Accept': 'text/html',
      }
    }), options.request).
    then(resp => resp.text()).
    then((html) => {
      options.payloadEl.innerHTML = html;
      if (options.onInsert) options.onInsert(el);
    }).
    catch(err => console.error(err));
};

// Page through all pages
//
// Returns a promise resolved with json if non-paginated resource OR array
// of all data.
//
// Assumes a response of
// {
//   links: { next: "url" }.
//   data: []
// }
//
// Example:
//   page(GET, "/some-resource").then(function (data) {
//     console.log(data); // etc - prints array of all data
//   });
//
export function page(fn, url, options, data){
  data = data || [];
  return new Promise(function(resolve, reject){
    fn(url, options).then(function(resp) {
      return resp.json();
    }).then(function(json) {
      // faster.... when we have es6
      // if (json.data && json.data.push) data.push(...json.data);
      if (json.data && json.data.push) data = data.concat(json.data);
      if (json.links && json.links.next) {
        page(fn, json.links.next, options, data).then(resolve).catch(reject);
      } else {
        resolve(data);
      }
    }).catch(reject);
  });
}

const m = Element.prototype.matches || Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector;

export function matches(element, selector) {
  if (selector.exclude != null) {
    return m.call(element, selector.selector) && !m.call(element, selector.exclude);
  } else {
    return m.call(element, selector);
  }
};

const paramReducer = (memo, param) => {
  memo[param.name] = param.value;
  return memo;
}

export function serializeElement(element, additionalParam) {
  var inputs, params;
  inputs = [element];
  if (matches(element, 'form')) {
    inputs = Array.prototype.slice.call(element.elements);
  }
  params = [];
  inputs.forEach(function(input) {
    if (!input.name || input.disabled) {
      return;
    }
    if (matches(input, 'fieldset[disabled] *')) {
      return;
    }
    if (matches(input, 'select')) {
      return Array.prototype.slice.call(input.options).forEach(function(option) {
        if (option.selected) {
          return params.push({
            name: input.name,
            value: option.value
          });
        }
      });
    } else if (input.checked || ['radio', 'checkbox', 'submit'].indexOf(input.type) === -1) {
      return params.push({
        name: input.name,
        value: input.value
      });
    }
  });
  if (additionalParam) {
    params.push(additionalParam);
  }

  return params.reduce(paramReducer, {});
};

export const usd = new Intl.NumberFormat(
  'en-US', {
  style: 'currency', currency: 'USD'
}).format;


