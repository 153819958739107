import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let radioButtons = this.element
      .querySelectorAll('input[type=radio][name="order[payment_method_id]"]');
    if (radioButtons[0]) radioButtons[0].checked = true;
  }
}

