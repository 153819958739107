import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["time", "date", 'datetime']

  connect() {
    let date = new Date(this.datetimeTarget.value);
    if (date.getYear()) {
      this.datetimeTarget.value = date.toISOString();
      this.dateTarget.value = date.toLocaleDateString("sv");
      this.timeTarget.value = date.toLocaleTimeString("sv");
      date.setSeconds(0);
      date.setMilliseconds(0);

      // added NOT IN hack-core
      this.dateTarget.value = date.toLocaleDateString("sv");
      this.timeTarget.value = date.toLocaleTimeString("sv");
    }
  }

  update() {
    let date = new Date(this.datetimeTarget.value);
    if (isNaN(date.getYear())) {
      date = new Date(Date.now());
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
    // Set date
    let date_value = new Date(this.dateTarget.value);
    if (date_value) {
      let year = date_value.getUTCFullYear();
      let month = date_value.getUTCMonth();
      let day = date_value.getUTCDate();
      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        date.setFullYear(year);
        date.setMonth(month);
        date.setDate(day);
      }
    }

    let [hour, minute] = this.timeTarget.value.split(":", 2);
    if (hour && minute) {
      date.setHours(hour);
      date.setMinutes(minute);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }

    if (date) {
      this.datetimeTarget.value = date.toISOString();
      this.dateTarget.value = date.toLocaleDateString("sv");
      this.timeTarget.value = date.toLocaleTimeString("sv");
    } else {
      this.datetimeTarget.value = "";
    }

  }

  clear() {
    this.dateTarget.value = "";
    this.timeTarget.value = "";
    this.datetimeTarget.value = "";
  }
}
