import { Controller } from "stimulus";

//import _ from "lodash";

import { GET, serializeElement } from '../helpers';

export default class extends Controller {

  static targets = [ 'input' ];

  autocomplete(event) {
    const url = new URLSearchParams();
    url.set('query', this.inputTarget.value);
    GET(`/results?${url.toString()}`, {headers: {
      'Accept': 'application/javascript',
    }})
      .then(response => {
        if (!response.ok) {
          response.text().then(text => {
            console.log(text);
          });
        } else {
          return response.text();
        }
      })
      .then(text => {
        window.App.appendScript(text);
      });
  }
}
