import { Controller } from "stimulus";

import _ from "lodash";

import { ready, GET, serializeElement, usd } from '../helpers';

// Basically a set of options is compared with the keys passed in the
// skus object keys.
//
// If sku and skus are not added to the controller via data attibutes,
// then it can be used
//
export default class extends Controller {
  // countTarget is where the number should be set as a text value
  // skuIdTarget(s) is the input to convey the sku to add to the cart on the server
  // skuPriceTarget(s) is the el to show the selected sku's price
  static targets = [ "count", "skuId", 'skuPrice', 'skuBuyNow' ]

  // Initialize the displayed count.
  // Set the sku if available. This is the only time it happens for products
  // with default skus.
  //
  connect() {
    this.countTarget.value = (parseInt(this.countTarget.value) || 1);
    this.setDefaultOptions();
    this.setSku();
    this.disableOptions();
  }

  // This is the quantity to add to the cart.
  get count() {
    return parseInt(this.countTarget.value) || 0;
  }

  decrement() {
    this.countTarget.value = Math.max((this.count - 1), 0);
  }

  increment() {
    this.countTarget.value = this.count + 1;
  }

  get allOptions() {
    return this.element.querySelectorAll('input[name^="options["], select[name^="options["]');
  }
  //get allOptionsValues() {
  //  return this.allOptions.map((option) => option.value);
  //}

  setDefaultOptions() {
    if (this.selectedSku) return;

    const groups = this.element.querySelectorAll('[data-fx="opt-group"]');
    groups.forEach(el => {
      const firstButton = el.querySelectorAll('input[type=radio]')[0];
      if (firstButton) firstButton.checked = true;
    });
  }

  disableOptions() {
    const skuKeys = Object.keys(this.skus);
    const availableIds = _.uniq(_.flatten(skuKeys.map((str) => JSON.parse(str))));
    const disabledInputs = this.allOptions.filter((el) => availableIds.indexOf(parseInt(el.value)) < 0);
    disabledInputs.forEach(el => {
      el.setAttribute('disabled', true);
      el.parentElement.querySelector('span.uk-button').setAttribute('disabled', true);
    });
  }

  // Lookup the selected options (a unique set of option ids)
  //
  lookup(arr) {
    const skuKeys = Object.keys(this.skus);
    const set = new Set(arr);

    var key = skuKeys.find(element => {
      return _.isEqual(new Set(JSON.parse(element)), set)
    })
    const sku = this.skus[key];

    if (!sku) {
      console.log(`lookup of [${arr}] failed in`, skuKeys);
    }
    return sku;
  }

  // Parse the skus data which is an object of keys of arrays marshalled
  // in a string and values are a sku object.
  //
  // {
  //   '[1, 2]': { id: 4, ... }
  // }
  //
  get skus() {
    const skusJson = this.element.getAttribute('data-skus');
    return JSON.parse(skusJson) || {};
  }

  // Parse the sku data which is a sku object.
  //
  // { id: 4, ... }
  //
  get sku() {
    const skuJson = this.element.getAttribute('data-sku');
    return JSON.parse(skuJson) || {};
  }

  // Get all the inputs (plus select) inside of this controller.:
  //
  // Really selected only...
  //
  get fields() {
    return serializeElement(this.element);
  }

  // Get the subset of fields that are options.
  //
  // Really selected only...
  //
  get optionValues() {
    const keys = Object.keys(this.fields); // all form fields
    const optionKeys = keys.filter(key => (key.startsWith('options['))); // all options selected
    const optionValues = optionKeys.reduce((memo, key) => {
      memo.push(parseInt(this.fields[key]));
      return memo
    }, []);
    return optionValues;
  }

  // There may be a (default) sku and option skus, but we always need
  // to prefer the option skus (more specific) and the server should be
  // responsible for managing the default sku.
  //
  get selectedSku() {
    let sku = undefined;

    if (Object.keys(this.skus).length === 0) {
      sku = this.sku;
    } else {
      // This is the path when there are multiple skus
      // Returns null if options don't match a valid combination
      const _optionValues = this.optionValues;
      sku = this.lookup(_optionValues);
    }

    return sku;
  }

  // If the options selected find a sku, then
  //  - set the sku id to be submitted
  //  - update the price to the sku price.
  //  - enable the submit buttons
  //
  // If there is no sku available, then
  //  - disable the submit buttons
  //
  // TODO There should only be one submit button, and the Buy Now
  // button should add the items to a new order, bypassing the current cart
  // (order).
  //
  setSku() {
    const submits = this.element.querySelectorAll('button[type=submit]')
    const _selectedSku = this.selectedSku; // cache it for efficency
    if (_selectedSku && _selectedSku.id) {
      console.log('setSku', _selectedSku);
      this.skuIdTarget.value = _selectedSku.id;
      if (this.hasSkuBuyNowTarget) {
        this.skuBuyNow.setAttribute('data-sku-id', _selectedSku.id);
      }
      this.skuPriceTargets.forEach(el => el.textContent = usd(_selectedSku.price_dollars));
      submits.forEach(el => el.disabled = false)
    } else {
      submits.forEach(el => el.disabled = true)
    }
  }
}

